<template>
  <div style="height: 100%;background: #ebedf0;">
    <div v-show="!pleshow" style="background: #ebedf0;">
      <div class="biao_ti" v-if="ljisok=='true'">
        <van-icon @click="back" class="fanhui" name="arrow-left" />
        <div class="bost" @click="toshow(1)">
          <div class>{{name}}的客户</div>
          <div>（{{list.length}}人）</div>
          <div class="heng" v-if="active == 1"></div>
        </div>
        <div class="bost" @click="toshow(2)">
          <div>{{name}}的团队</div>
          <div>（{{teamlist.length}}人）</div>
          <div class="heng" v-if="active == 2"></div>
        </div>
        <div class="bosttan" @click="totianjia">添加</div>
      </div>
      <div class="biao_ti" v-else>
        <van-icon @click="back" class="fanhui" name="arrow-left" />
        <div class="tis" @click="toshow(2)">
          <div>{{name}}的客户（{{teamlist.length}}人）</div>
        </div>
      </div>
      <div class="mian">
        <div class="heds" v-if="active == 1">
          <van-row style="font-size: 13px;">
            <van-col span="2" class="ls">#</van-col>
            <van-col span="6" class="ls">车牌</van-col>
            <van-col span="4" class="rs">车主姓名</van-col>
            <van-col span="6" class="ls">电话号码</van-col>
            <van-col span="6" class="rs">注册时间</van-col>
          </van-row>

          <van-row v-for="(item,idx) in list" :key="idx">
            <van-swipe-cell>
              <van-col span="2" class="ls">{{idx+1}}</van-col>
              <van-col span="6" class="ls" style>
                <span style="vertical-align: middle;">{{item.carNo}}</span>
              </van-col>
              <van-col span="4" class="rs">{{item.customerName}}</van-col>
              <van-col span="6" class="ls">{{item.phone}}</van-col>
              <van-col span="6" class="rs" @click.native="totuijianxq(item)">
                <span style="vertical-align: middle">{{item.createdDate.substring(0,10)}}</span>
                <van-icon style="vertical-align: middle;margin-top: 1px;" name="arrow" />
              </van-col>
              <template #right>
                <van-button
                  square
                  @click="toshanchutuijian(item)"
                  text="删除"
                  type="danger"
                  class="delete-button"
                />
                <van-button
                  square
                  @click="toaddtuijian(item)"
                  text="添加"
                  type="primary "
                  class="delete-button"
                />
              </template>
            </van-swipe-cell>
          </van-row>
        </div>
        <div class="hedst" v-if="active == 2">
          <div class="shijian" @click="show= true">
            <span style="vertical-align: middle;">{{times}}</span>
            <van-icon class="xiaqu" name="play" />
          </div>
          <van-row style="font-size: 13px;">
            <van-col span="2" class="ls">#</van-col>
            <van-col span="6" class="ls">员工姓名</van-col>
            <van-col span="4" class="rs">成员数</van-col>
            <van-col span="4" class="rs">客户数</van-col>
            <van-col span="4" class="ls">月单数</van-col>
            <van-col span="4" class="rs">月收入</van-col>
          </van-row>
          <div class="neimian">
            <van-row v-for="(item,idx) in teamlist" :key="idx">
              <van-swipe-cell>
                <van-col span="2" class="ls">{{idx+1}}</van-col>
                <van-col span="6" class="ls" style>
                  <span style="vertical-align: middle;">{{item.name}}</span>
                </van-col>
                <van-col span="4" class="rs">{{item.memberCounts}}</van-col>
                <van-col span="4" class="rs">{{item.customerCounts}}</van-col>
                <van-col span="4" class="ls">{{item.mouthCounts}}</van-col>
                <van-col span="4" class="rs" @click.native="tomy_perf(item)">
                  <span style="vertical-align: middle">{{item.mouthAmount}}</span>
                  <van-icon style="vertical-align: middle;margin-top: 1px;" name="arrow" />
                </van-col>
                 <div class="duoyu">
                <div span="12" class="divban">
                  <img class="logoimg" src="../../assets/img/dianhualogo.png" alt />
                  <span style="vertical-align: middle;" v-if="item.phone">{{item.phone}}</span>
                  <span v-else style="color: red;">未注册</span>
                </div>
                <div span="12" class="divban">
                  <img class="logoimg" src="../../assets/img/chelogo.png" alt />
                  <span style="vertical-align: middle;" v-if="item.carNo">{{item.carNo}}</span>
                  <span v-else style="color: red;">未注册</span>
                </div>
              </div>
                <template #right>
                  <van-button
                    square
                    @click="toshanchu(item)"
                    text="删除"
                    type="danger"
                    class="delete-button"
                  />
                </template>
              </van-swipe-cell>
         
            </van-row>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <van-popup v-model="show" position="bottom" :style="{ height: '315px' }">
        <van-datetime-picker
          v-model="currentDate"
          @confirm="toshijian"
          @cancel="show =false"
          type="year-month"
          title="选择年月"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
        />
      </van-popup>
      <van-popup closeable v-model="showleft" position="left" class="tanris">
        <div class="tiantest">{{active==1?'添加客户':'添加团队长'}}</div>
        <van-search v-model="value" @search="onSearch" show-action placeholder="请输入车牌或电话号码查找">
          <template #action>
            <div @click="onSearch">查找</div>
          </template>
        </van-search>
        <van-row style="font-size: 13px;">
          <van-col span="2" class="ls">#</van-col>
          <van-col span="6" class="ls">车牌</van-col>
          <van-col span="6" class="rs">车主姓名</van-col>
          <van-col span="6" class="ls">电话号码</van-col>
          <van-col span="4" class="rs"></van-col>
        </van-row>
        <van-row v-for="(item,idx) in userlist" class="tianname" :key="idx">
          <van-col span="2" class="ls">{{idx+1}}</van-col>
          <van-col span="6" class="ls" style>
            <span style="vertical-align: middle;">{{item.carNo}}</span>
          </van-col>
          <van-col span="6" class="rs">{{item.customerName}}</van-col>
          <van-col span="6" class="ls">{{item.phone}}</van-col>
          <van-col span="4" class="rs" style="color: #0d906e;" @click.native="toaddtianjia(item)">添加</van-col>
        </van-row>
      </van-popup>
      <van-dialog v-model="showren" title="添加客户信息" show-cancel-button :before-close="onBeforeClose">
        <div class="tanhes">
          <div class="tanh1">
            <span class="yonghu">微信名称：</span>
            {{renform.customerName}}
          </div>
          <div class="tanh1">
            <span class="yonghu">用户ID：</span>
            <input v-model="mallUserId" type="tel" />
          </div>
          <div class="tanh1">
            <span class="yonghu">车牌号码：</span>
            <input @click="getple" readonly v-model="renform.carNo" type="text" />
          </div>
          <div class="tanh1">
            <span class="yonghu">手机号码：</span>
            <input v-model="renform.phone" type="tel" />
          </div>
        </div>
      </van-dialog>
    </div>
    <carPlate ref="ple" v-show="pleshow" :plate_number="renform.carNo" @getPlate="getPlate"></carPlate>
  </div>
</template>

<script>
import {
  sellManteam,
  sellMandelete,
  tuokelonger,
  searchonline,
  tokerrecommended,
  bringteam,
  deleteoffId,
  savecarInfo
} from "../../api/check";
import carPlate from "../../components/carplate.vue";
import vueQr from "vue-qr";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: { vueQr, carPlate },
  // mixins: [preventBack], //注入
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      list: [], //
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2030, 10, 1),
      currentDate: new Date(),
      teamlist: [],
      downloadData: {
        url: "",
        icon: ""
      },
      uid: this.$route.query.uid,
      pleshow: false,
      name: this.$route.query.name,
      gid: this.$route.query.gid,
      ljisok: this.$route.query.ljisok,
      show: false,
      showren: false,
      mallUserId: "",
      showleft: false,
      value: "",
      times: "",
      mouthDate: "", //当前年月
      orderNumber: "",
      active: 1,
      index: 0,
      count: "",
      renform: {},
      token: "",
      isok: false,
      userlist: []
    };
  },
  methods: {
    toaddtianjia(item) {
      if (this.active == 1) {
        var data = {
          carNo: item.carNo,
          customerId: this.uid,
          flag: true,
          garageName: this.shangjia.name,
          gid: this.shangjia.id,
          name: item.customerName,
          phone: item.phone
        };
        tokerrecommended(data).then(res => {
          if (res.code == 200) {
            this.$toast("添加客户成功！");
            this.namelist();
            // this.teamlond();
            this.showleft = false;
          } else {
            this.$toast(res.data.message);
          }
        });
      } else {
        var data = {
          offId: item.offId,
          tokerId: this.uid,
          gid: this.shangjia.id
        };
        bringteam(data).then(res => {
          if (res.code == 200) {
            this.$toast("添加团队成功！");
            this.teamlond();
            this.showleft = false;
          } else {
            this.$toast(res.data.message);
          }
        });
      }
    },
    toaddtuijian(item) {
      this.renform = JSON.parse(JSON.stringify(item));
      this.mallUserId = item.offId;
      this.renform.carNo = "";
      this.showren = true;
    },
    //删除推荐的客户
    toshanchutuijian(item) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否删除推荐客户： " + item.customerName
        })
        .then(() => {
          var data = {
            gid: item.gid,
            offId: item.offId,
            carNo:item.carNo
          };
          deleteoffId(data).then(res => {
            if (res.code == 200) {
              this.$toast("删除成功！");
              this.namelist();
            } else {
              this.$toast(res.data.message);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    ///左侧弹窗
    totianjia() {
      this.value = "";
      this.userlist = [];
      this.showleft = true;
    },
    //认证
    onBeforeClose(action, done) {
      if (action === "confirm") {
        var _this = this;
        if (_this.mallUserId == "") {
          _this.$toast("请输入用户ID");
          return done(false);
        } else if (_this.mallUserId != _this.renform.offId) {
          _this.$toast("请输入正确的用户ID");
          return done(false);
        }
        // 你的逻辑代码
        if (_this.renform.phone == "" || !_this.renform.phone) {
          _this.$toast("请输入手机号码");

          return done(false);
        } else if (!/^1[3456789]\d{9}$/.test(_this.renform.phone)) {
          _this.$toast("手机号码格式错误");
          return done(false);
        } else if (_this.renform.carNo.length < 6) {
          _this.$toast("请填写车牌号码");
          return done(false);
        }
        const data = {
          carNo: _this.renform.carNo,
          gid: _this.renform.gid,
          mallUserId: _this.mallUserId,
          phone: _this.renform.phone,
          haveChange: 1,
          haveAdopt: 1,
          onDefault: true //是否默认车辆
        };
        savecarInfo(data).then(e => {
          // loading.clear();
          if (e.code == 200) {
            this.namelist();

            this.showren = false;
            this.$toast("添加成功！");
          }
        });
      } else {
        return done();
      }
    },
    //打开键盘
    getple() {
      this.pleshow = true;
      this.$refs.ple.clickShowKeyboard();
    },
    getPlate(val) {
      console.log(val);
      this.renform.carNo = val;
      this.pleshow = false;
    },
    totuijianxq(item) {
      console.log(item);
      if (item.createdDate == "待注册") {
        this.renform = JSON.parse(JSON.stringify(item));
        this.mallUserId = "";
        this.showren = true;
      } else {
        this.$router.push({
          name: "indextwo",
          query: {
            customerId: item.offId,
            carNo: item.carNo,
            customerName: item.customerName,
            phone: item.phone,
            gid: item.gid,
            showisok: "消费",
            gidisok: true
          }
        });
      }
    },
    //搜索
    onSearch() {
      var data = {
        gid: this.gid,
        keyWord: this.value
      };
      searchonline(data).then(res => {
        if (res.code == 200) {
          this.userlist = res.data;
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    toshanchu(item) {
      const u = navigator.userAgent,
        app = navigator.appVersion;
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      console.log("是否isiOS==",isiOS,"是否isAndroid==",isAndroid)
      console.log("当前删除对象===",item)
      console.log("当前gid==",item.gid)
      console.log("当前uid==",item.uid)
      console.log("当前sid==",isAndroid ? window.androidjs.getLocalData("getUserId") : this.userInfo.id)
      
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否删除团队成员： " + item.name
        })
        .then(() => {
          var data = {
            uid: item.uid,
            gid: item.gid,
            sid: isAndroid ? window.androidjs.getLocalData("getUserId") : this.userInfo.id
          };
          sellMandelete(data).then(res => {
            console.log("请求成功后返回参==",res)
            if (res.code == 200) {
              this.$toast("删除成功！");
              this.teamlond();
            } else {
              this.$toast(res.data.message);
            }
          });
        })
        .catch((err) => {
          console.log("发起请求失败了===",err)
          // on cancel
        });
    },
    toshijian(e) {
      var _this = this;
      this.mouthDate = this.timeFormat(e, 1);
      this.times = this.timeFormat(e, 2);
      _this.teamlond();
      this.show = false;
    },
    timeFormat(time, type) {
      // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      var months = month < 10 ? "0" + month : month;
      if (type == 1) {
        return year + "-" + months;
      } else {
        return year + "年" + months + "月";
      }
    },
    toshow(idx) {
      this.active = idx;
      if (idx == 3) {
        this.malist();
      }
    },
    // toyeji() {
    //   this.$router.push({
    //     name: "My_performance",
    //     query: {
    //       uid: this.uid,
    //       name: this.name,
    //       isok: "true",
    //       gid:this.gid
    //     }
    //   });
    // },
    tomy_perf(item) {
      this.$router.push({
        name: "My_performance",
        query: {
          uid: item.uid,
          name: item.name,
          gid:item.gid,
          isok: "true"
        }
      });
    },

    teamlond() {
      sellManteam({ uid: this.uid, mouthDate: this.mouthDate }).then(res => {
        if (res.code == 200) {
          this.teamlist = res.data;
        } else {
          this.$toast(res.data.message);
        }
      });
    },

    addDate() {
      var nowDate = new Date();
      let date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate()
      };

      var month = date.month < 10 ? "0" + date.month : date.month;
      var dates = date.date < 10 ? "0" + date.date : date.date;
      // let systemDate = date.year + "-" + 0 + date.month + "-" + 0 + date.date;
      let systemDate = date.year + "-" + month;
      this.mouthDate = systemDate;
      this.times = date.year + "年" + month + "月";
      console.log(systemDate);
    },
    namelist() {
      //
      // this.userInfo.id
      tuokelonger({ customerId: this.uid }).then(res => {
        if (res.code == 200) {
          this.list = res.data;
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "user"
      // });
    }
  },
  created() {
    this.addDate();
    this.namelist();
    this.teamlond();
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
// ---------------------
.keyboard {
  z-index: 9999;
}
input {
  padding: 0 6px;
}
.van-dialog {
  width: 300px;
  z-index: 9;
}
.tanhes {
  padding: 0 20px 20px 20px;
  overflow: hidden;
}
.tanh1 {
  line-height: 26px;
  font-size: 14px;
  margin-top: 6px;
  margin-bottom: 6px;
  padding: 4px 0;
}
// ---------------------
.bost {
  display: inline-block;
  width: 40%;
  height: 46px;
  line-height: 18px;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  position: relative;
}
.bosttan {
  width: 10%;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  line-height: 46px;
  color: #0d906e;
}
.tanris {
  width: 100%;
  height: 100%;
}
.tiantest {
  font-size: 18px;
  padding: 15px 0 5px 0;
  text-align: center;
  // font-weight: bold;
}
.biao_ti {
  padding: 0 10px 0 30px;
  height: 46px;
  background: #fff;
  width: 100%;
  font-size: 13px;
  // font-weight: bold;
  //   line-height: 46px;
  text-align: center;
  color: #333;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  overflow: hidden;
}
.yeji {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  margin-right: 15px;
  margin-top: 10px;
  color: #4169e1;
}
.mian {
  padding-top: 46px;
  height: 100%;
}
.shijian {
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
}
.tis {
  height: 46px;
  //   margin-left: 40px;
  line-height: 46px;
  font-size: 14px;
  // margin-top: 4px;
  text-align: center;
  overflow: hidden;
  position: relative;
}
.heng {
  height: 1px;
  background: #333;
  width: 100%;
  position: absolute;
  bottom: 5px;
  left: 0;
}
.fanhui {
  line-height: 46px;
  padding: 0 10px;
  position: absolute;
  left: 0;
  top: 0;
}
.heds {
  font-size: 12px;
  //   padding-top: 46px;
  margin: 12px 10px 0 10px;
  background: #fff;
  border-radius: 8px;
  min-height: 220px;
}
.hedst {
  font-size: 12px;
  //   padding-top: 46px;
  margin: 12px 10px 0 10px;
  background: #fff;
  border-radius: 8px;
  height: 95%;
  overflow: hidden;
}
.neimian {
  height: 85%;
  overflow-y: auto;
}
.van-col {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 45px;
}
.van-row {
  text-align: center;
  min-height: 45px;

  border-bottom: 1px solid #f5f5f5;
}
.tianname {
  overflow: hidden;
  font-size: 13px;
}
.neimian .van-col {
  height: 45px;
}
.xiaqu {
  transform: rotate(90deg);
  vertical-align: middle;
}
.imgse {
  background: #fff;
  height: 100%;
  overflow: hidden;
  // border-radius: 15px;
  // margin: 10px 20px;
  padding: 10px 0;
}
.ma {
  text-align: center;
  // display: block;
  margin: auto;
  margin-top: 30%;
}
.myma {
  // display: flex;
  // align-items: center;
  // height: 40px;
}
.logoimg {
  width: 18px;
  vertical-align: middle;
  margin-right: 10px;
  
}
.divban {
  float: left;
  width: 50%;
    line-height: 35px;
     border-top: 1px solid #f5f5f5;
}

</style>